/* Override button styles provided by OneTrust to match other footer links */
.app-footer nav button#ot-sdk-btn.ot-sdk-show-settings {
  color: var(--qualcomm_text_secondary);
  background-color: inherit;
  cursor: pointer;
  font-size: 12px;
  line-height: 16px;
  border: 0;
  padding: 0;
  font-family: inherit;
  transition: none;
}

.app-footer nav button#ot-sdk-btn.ot-sdk-show-settings:hover {
  background-color: rgba(0, 0, 0, 0);
}
