@font-face {
  font-family: 'Qualcomm Next';
  src: url('QualcommNext-ThinItalicWEB.woff2') format('woff2');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Qualcomm Next';
  src: url('QualcommNext-ThinWEB.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Qualcomm Next';
  src: url('QualcommNext-RegularWEB.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Qualcomm Next';
  src: url('QualcommNext-ItalicWEB.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Qualcomm Next';
  /* 8309: There is a corruption in the woff2 file, use the otf instead. */
  src: url('QualcommNext-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Qualcomm Next';
  src: url('QualcommNext-MediumItalicWEB.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
}
